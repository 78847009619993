import React from "react";

import { throttle } from "throttle-debounce";

import Textfield from '../../atom/Textfield/Textfield'
import Pagination from "../../element/Pagination/Pagination";
import SimpleSelectfield from "../../atom/Selectfield/SimpleSelectfield";

import { getToken, imagePathForFormat, filesFormatsList, formatDate, formatShortDate } from "../../../../lib/lib";
import { getAssets, getAsset } from "../../../../data/api";


class ImageSelector extends React.Component {

  static defaultProps = {
    showHeadline: true
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query: '',
      count: 0,
      pagesCount: 0,
      limit: props?.limit ?? 12,
      currentPage: 0,
      orderField: 'updated_at',
      orderDirection: 'desc',
      filterByType: [],
      filterByOwner: [],
      files: [],
      showOnlyFiles: false,
      selectedFormat: 'original',
      selectedAsset : {},
      formats: [
        { id: 'original', option: 'Originalformat' },
        { id: 'one_by_one', option: '1:1 (Quadratisch)' },
        { id: 'two_by_three', option: '2:3 (Hochformat)' },
        { id: 'three_by_two', option: '3:2 (Querformat)' },
        { id: 'sixteen_by_nine', option: '16:9 (Breitbild)'},
        { id: 'five_by_two', option: '5:2 (Panorama)' },
      ],
    }

    this.searchThrottled = throttle(1000, this.loadData);
  }

  handlePaginationClick = async (e, page) => {
    e.preventDefault()
    this.setState({
      ...this.state,
      currentPage: page
    }, async () => {
      await this.loadData()
    })
  }

  handleSelectLimit = (limit) => {
    this.setState({ limit, currentPage: 0 }, async () => {
      await this.loadData()
    })
  }

  onSelectFormat = (value, asset) => {
    this.setState({ selectedFormat: value, selectedAsset: asset })
  }

  onChange = e => {
    this.setState({ query: e.target.value }, async () => {
      this.searchThrottled()
    })
  }

  selectImage = () => {
    this.props.selectImageCallback(this.state.selectedAsset, this.state.selectedFormat)
  }

  selectFile = (e, file) => {
    e.preventDefault()
    this.props.selectImageCallback(file)
  }

  loadData = async () => {
    const offset = this.state.currentPage * this.state.limit

    let filterByType = [...this.state.filterByType]

    if (this.state.showOnlyFiles) {
      filterByType = filesFormatsList()
    }

    const assets = await getAssets(getToken(),
      this.state.limit,
      offset,
      this.state.orderField,
      this.state.orderDirection,
      filterByType,
      this.state.filterByOwner,
      this.state.query)

    const pagesCount = Math.ceil(assets.count / this.state.limit)

    this.setState({
      ...this.state,
      pagesCount: pagesCount,
      count: assets.count,
      assets: assets.assets,
      loading: false,
    })
  }

  isLikeImage = (asset) => {
    return asset.content_type === 'image' || asset.content_type === 'icon';
  }

  imagePathForFormat = (asset) => {
    if (this.state.selectedAsset && this.state.selectedAsset.id === asset.id) {
      return imagePathForFormat(this.state.selectedFormat)
    }
    return 'file_path'
  }

  renderPreview = (asset) => {
    return (
      <div>
        <div className="dd-image-selector__file-preview">
          {(this.isLikeImage(asset))
            ?
            <img className="dd-image-picker__image" src={asset[this.imagePathForFormat(asset)]} alt="Preview"  />
            :
            <img className="dd-media__file-icon" alt="Preview" src={process.env.PUBLIC_URL + '/icons/static/file-' + asset.content_type + '.svg'} />
          }
          <div className="dd-image-selector__file-select">
            {!this.state.showOnlyFiles && this.renderFormatSelect(asset) }
          </div>
        </div>
      </div>
    )
  }

  renderFormatSelect = (asset) => {
    let formats = [...this.state.formats]

    //FIXME
    if (asset.content_type === 'icon' || asset.content_type === 'mp4') {
      formats =  [
        { id: 'original', option: 'Originalformat' }
      ]
    }


    if (this.state.selectedAsset && this.state.selectedAsset.id === asset.id) {
      return <SimpleSelectfield
        key={asset.id}
        label=""
        name={`format-${asset.id}`}
        firstOptionValue=""
        firstOptionLabel="Bitte auswählen"
        indexLabel="id"
        optionLabel="option"
        options={formats}
        onSelect={(value) => this.onSelectFormat(value, asset)}
        selected={this.state.selectedFormat}
      />
    } else {
      return <SimpleSelectfield
        key={asset.id}
        label=""
        name={`format-${asset.id}`}
        firstOptionValue="null"
        firstOptionLabel="Format auswählen"
        indexLabel="id"
        optionLabel="option"
        options={formats}
        onSelect={(value) => this.onSelectFormat(value, asset)}
      />
    }
  }

  async componentDidMount() {
    let formats = [...this.state.formats ]
    let filterByType  = []
    let showOnlyFiles = false

    let selectedFormat = 'original'
    let query = ''
    let asset = {}
    const selectedAsset = {}

    if (this.props.selectedAssetId) {
      try {
        asset = await getAsset(getToken(), this.props.selectedAssetId)
        query = asset.asset.filename
        selectedAsset.id = this.props.selectedAssetId
      } catch (error) {
        console.log("Asset not found: ", this.props.selectedAssetId)
      }
    }

    if (this.props.selectedFormat) {
      selectedFormat = this.props.selectedFormat
    }

    const allowedFormats = this.props.allowedFormats || []

    if (allowedFormats.length) {
      formats = this.state.formats.filter(format => allowedFormats.includes(format.id))
    }

    if (this.props.fileTypes && this.props.fileTypes.length === 1 && this.props.fileTypes[0] === 'files') {
      showOnlyFiles = true
    }
    else if (this.props.fileTypes) {
      filterByType = this.props.fileTypes
    }

    this.setState({ filterByType, formats, showOnlyFiles, selectedAsset, selectedFormat, query }, async ()  => {
      await this.loadData()
    })
  }

  render() {
    return (
      <div className="dd-image-selector">
        { this.props.showHeadline && <h4 className="dd-image-selector__headline">{ this.state.showOnlyFiles ? 'Datei auswählen' : 'Bild oder Datei auswählen' }</h4> }

        <div className="dd-image-selector__search">
          <div className="form-row">
            <div className="col-sm-9">
              <Textfield
                name="filter"
                label=''
                type="text"
                placeholder="Bild oder Datei suchen"
                defaultValue={this.state.query}
                handleChange={this.onChange}
              />
            </div>
          </div>
        </div>

        <ul className="dd-image-selector__list">
          {this.state.assets && this.state.assets.map((asset, index) => {
            return <li key={index} className={`dd-image-selector__file ${(this.state.selectedAsset && this.state.selectedAsset.id === asset.id) ? 'dd-image-selector__file--selected': ''}`}>

              {this.state.showOnlyFiles &&
                <button className="dd-image-selector__file-button" onClick={(e) => this.selectFile(e, asset)}>
                  {this.renderPreview(asset)}
                </button>
              }

              {!this.state.showOnlyFiles && this.renderPreview(asset) }

              <div className="dd-image-selector__file-title">{asset.title}</div>
              <div className="dd-image-selector__file-title">{asset.filename}</div>
              <div className="dd-image-selector__file-date">{formatShortDate(asset.updated_at)}</div>

            </li>
          })}
        </ul>

        <div className="dd-image-selector__footer">
          <div className="dd-image-selector__button">
            {this.state.selectedAsset &&
              <button className="dd-btn dd-btn--primary" onClick={this.selectImage}>
                Auswahl bestätigen
              </button>
            }
          </div>

          <div className="dd-image-selector__pager">
            <Pagination
              total={this.state.pagesCount}
              current={this.state.currentPage}
              onChange={this.handlePaginationClick}
              onSelect={this.handleSelectLimit}
              limit={this.state.limit}
            />
          </div>
        </div>

      </div>
    )
  }
}

export default ImageSelector;
