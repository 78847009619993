import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { Slate, Editable, ReactEditor, withReact, useSlate } from 'slate-react'
import { Editor as SlateEditor, Transforms, Text, createEditor } from 'slate'
import isUrl from 'is-url'
import { publicFilePath } from "../../../../lib/lib";
import { css } from '@emotion/css'
import { withHistory } from 'slate-history'

import { Button, Icon, Menu, Portal } from './components'
import { Range } from 'slate'

import Overlay from "../../element/Overlay/Overlay";
import PageSelector from "../../module/PageSelector/PageSelector";

const LIST_TYPES = ['numbered-list', 'bulleted-list']


const Editor = (props) => {
  const [value, setValue] = useState(props.initValue)
  const onChange = (value) => {
    setValue(value)
    props.onChange(props.id, value)
  }

  const editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    [])

    const renderElement = useCallback(props => <Element {...props} />, [])

    return (
      <div className="dd-editor">
      <Slate key={props.id} editor={editor} value={value} onChange={value => onChange(value)}>
        <HoveringToolbar />
        <Editable
          onKeyDown={event => {
            if (event.key === 'Enter' && event.shiftKey) {
              Transforms.setNodes(editor,
                { shiftreturn: true },
                { match: n => Text.isText(n), split: true }
              )}
            else if (event.key === 'Enter') {
              Transforms.setNodes(editor,
                { shiftreturn: false },
                { match: n => Text.isText(n), split: true }
              )
            }}
          }
          renderLeaf={props => <Leaf {...props} />}
          renderElement={renderElement}
          className='textfield__editor'
          placeholder={props.initValue[0].children[0].placeholder || "Enter some text..."}
          onDOMBeforeInput={event => {
            switch (event.inputType) {
              case 'formatBold':
                return toggleFormat(editor, 'bold')
              case 'formatItalic':
                return toggleFormat(editor, 'italic')
              case 'formatUnderline':
                //return toggleFormat(editor, 'underline')
                return toggleBlock(editor, 'bulleted-list')
              default:
                return
            }
          }}
        />
      </Slate>
    </div>
  )
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'h1':
      return <h1 {...attributes}>{children}</h1>
    case 'h2':
      return <h2 {...attributes}>{children}</h2>
    case 'h3':
      return <h3 {...attributes}>{children}</h3>
    case 'h4':
      return <h4 {...attributes}>{children}</h4>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return (
        <a {...attributes} href={element.url}>{children}</a>
      )
    default:
      if (element.children[0].shiftreturn) {
        return <span {...attributes}>{children}<br/></span>
      } else {
        return <p {...attributes}>{children}</p>
      }
  }
}

const toggleBlock = (editor, format) => {

  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = SlateEditor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const toggleFormat = (editor, format) => {
  const isActive = isFormatActive(editor, format)
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true }
  )
}

const isFormatActive = (editor, format) => {
  const [match] = SlateEditor.nodes(editor, {
    match: n => n[format] === true,
    mode: 'all',
  })
  return !!match
}

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = SlateEditor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {

  if (isLinkActive(editor) && url.length === 0) {
    unwrapLink(editor)
  } else {

    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
      type: 'link',
      url,
      children: isCollapsed ? [{ text: url }] : [],
    }


    if (isCollapsed) {
      Transforms.insertNodes(editor, link)
    } else {
      Transforms.wrapNodes(editor, link, { split: true })
      Transforms.collapse(editor, { edge: 'end' })
    }
  }
}


const Leaf = ({ attributes, children, leaf }) => {

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underlined) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}


const HoveringToolbar = () => {
  const ref = useRef()
  const editor = useSlate()

  const [overlayActive, setOverlayActive]     = useState(false)
  const [editorSelection, setEditorSelection] = useState()
  const [linkURL, setLinkURL] = useState()

  const onPageSelect = (page) => {
    let url = ""
    editor.selection = editorSelection

    if (page.buttonURL && page.buttonURL.length > 0) {
      url = page.buttonURL
    }
    else if (page.id && page.path && page.path.length > 0) {
      if (page.lang && page.lang.length > 0) {
        url = "/" + page.lang + "/" + page.path
      } else {
        url = "/" + page.path
      }
    }
    else if (page.file) {
      url = publicFilePath(page.file);
    }

    insertLink(editor, url)
    setOverlayActive(false)
  }

  const openOverlay = (state, editorSelection) => {
    const [link] = SlateEditor.nodes(editor, { match: n => n.type === 'link' })

    //reset cached value
    setLinkURL("")

    if (isLinkActive(editor)) {
      if (link && link[0] && link[0].url) {
        setLinkURL(link[0].url)
      }
    }

    setOverlayActive(state)
    setEditorSelection(editorSelection)
  }

  const deleteLink = (state, editorSelection) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor)
    }
  }

  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el) {
      return
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      SlateEditor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style')
      return
    }

    const domSelection = window.getSelection()
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    el.style.opacity = 1
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
    el.style.left = `${rect.left +
      window.pageXOffset -
      el.offsetWidth / 2 +
      rect.width / 2}px`
  })

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          padding: 8px 7px 6px;
          position: absolute;
          z-index: 1;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          background-color: #222;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
      >
        <FormatButton format="bold" icon="format_bold" />
        <FormatButton format="italic" icon="format_italic" />
        <FormatButton format="underlined" icon="format_underlined" />
        <LinkButton   format="link" icon="insert_link" onClickAction={openOverlay}/>
        <LinkButton   format="link_off" icon="link_off" onClickAction={deleteLink}/>
      </Menu>
      {overlayActive &&
        <Overlay closeOverlayCallback={() => setOverlayActive(false)}>
          <PageSelector
            buttonURL={linkURL}
            disableFiles={false}
            selectPageCallback={(page) => onPageSelect(page)}
          />
        </Overlay>
      }
    </Portal>
  )
}

const FormatButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      reversed
      active={isFormatActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleFormat(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const LinkButton = ({ format, icon, onClickAction }) => {
  const editor = useSlate()

  return (
    <Button
      reversed
      active={isFormatActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        onClickAction(true, editor.selection)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

export default Editor
